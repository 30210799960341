.footer {
  position: absolute;
  bottom: 0;
  padding: 0.3rem;
  overflow: hidden;
  width: 100%;
}

.content {
  text-align: center;
}

html, body, div#root {
  height: 100%;
  background: #f0f0f0;
}

div#root {
  display: flex;
  align-items: center;
  justify-content: center;
}